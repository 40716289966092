import React from 'react';
import { Container, Row, Col, Breadcrumb} from "react-bootstrap";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import {Helmet} from "react-helmet";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Post } from "../../components/Core";
import DataTable from '../../components/data/banquePro/banquePro'
import DataTableMobile from '../../components/data/banquePro/banqueProMobile'
import Disclosure from '../../components/Disclosure'
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding} from '@fortawesome/free-solid-svg-icons'

function startabusiness() {
	var d = new Date();
	var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
	var n = month[d.getMonth()];
	var y = d.getFullYear();


    return (
        <PageWrapper footerDark>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Banque pour Startup - Meilleure banque pro pour votre startup</title>
                <meta name="description" content="Vous avez une startup? Trouvez la meilleure banque pro avec bankbank." />
            </Helmet>
          <Header/>
          <Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
          <Container style={{maxWidth: 1280, marginTop: 28}}>
         
          <Breadcrumb>
            <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Banque Pro</Link></Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Banque pro pour startup</Breadcrumb.Item>
          </Breadcrumb>
  

          <h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}>Banque Pro Pour Startup</h1>
          <h2 id='compareh2' style={{color: 'rgb(41, 49, 61)'}}>Trouvez la meilleure banque pro avec un comparatif tarifs, cartes bancaires et comptabilité intégrée (mise à jour {n} {y})</h2>
          <div id='onetwothreemobile'>
          <p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur banques pro Startup</span> pour déterminer vos priorités : employé.e.s tarifs, services inclus.</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponibles dès aujourd’hui.</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleure banque pro</span>, celle qui correspond le plus à vos besoins.</p>
          </div>

          <Disclosure/>
          <Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
          
          </Row>
          </Container>
          </Container>  


     
          <Container style={{maxWidth: 1280, marginTop: -130}}>
        
          <Tabs style={{maxWidth: 1234}}>
    <TabList style={{marginLeft: 5}}>
      <Tab style={{marginLeft: 0, width: '100%', textAlign: 'center'}}><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> Banques pour Startup </Tab>

    </TabList>

    <TabPanel>
      <DataTable type='Société' row={[35,15,2,16,36,18,30,9,38,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[35,15,2,16,36,18,30,9,38,40]}id='mobileTable'/>
    </TabPanel>

  </Tabs>
            <Row className="justify-content-center">
              <Col>
   
            

       
              </Col>
            </Row>
             
          </Container>
          <Container style={{marginTop: 100, marginBottom : 100}}>
			<Post>

			<h2>Quelle est la meilleure banque pour votre startup ?</h2>
			<p>
La notion de startup est toujours compliqué à définir. Une startup, c’est juste une nouvelle entreprise qui démarre peu importe le secteur ? Ou c’est une entreprise obligatoirement tech ? Ou c’est une entreprise qui à une forte croissance ?
</p>
<StaticImage
		  	src='../../images/banque-pro-startup.jpeg'
			width={1200}
			alt='Banque à distance'
			placeholder='tracedSVG'
			id='pickky'
			/>
<p>
Ici, pour faciliter les critères de choix, nous avons pris la définition d’une boîte tech qui démarre (même si nous pensons vraiment que l’esprit startup commence dès qu’un entrepreneur qui tente l’aventure).
</p>

<h3>Quels sont vos besoins bancaires d’une startup ?</h3>
<p>
Dans cette définition de startup, on parle donc d’une création d’entreprise. Et comme c’est une création, vous allez avoir besoin de déposer votre capital social auprès d’une banque ou un notaire. Peut-être avez-vous aussi besoin d’établir vos statuts et d’obtenir une aide juridique.
</p><p>
La nature tech de l’activité veut probablement dire que vous n’aurait pas besoin d’encaisser de liquide ni de chèque bancaire.
</p><p>
On basera aussi notre classement sur moins de dix collaborateur au lancement. Le nombre d’employés ou la taille de votre équipe à un impact principalement si vous donner une carte bancaire à chacun. Dix collaborateurs avec cartes bancaires indépendants est déjà un bon 1er niveau de croissance à atteindre au moment de la création. On s’assurera évidemment de sélectionner une banque qui sera flexible et pourra grossir avec vous.
</p><p>
Souvent les serveurs et le software d’une startup tech est payé en dollars. Une solution de carte bancaire avec frais réduits sur devise sera donc favorisé.
</p><p>
La chose que la majorité des entrepreneurs détestent au moment d’un lancement c’est la comptabilité ! Notre comparatif mettra aussi en avant les banques qui propose une intégration comptable performantes ainsi que la digitalisation de vos notes de frais.
</p>
<ul>
	<li>Résumé du profil bancaire pour startup</li>
	<li>Création d’entreprise avec dépôt de capital</li>
	<li>Pas de besoin d’encaisser chèque ou liquide</li>
	<li>Moins de 10 collaborateurs</li>
	<li>Paiement en devises moins chère</li>
	<li>Comptabilité simplifié</li>
</ul>

		</Post>
		</Container>
	
		<Footer/>
      </PageWrapper>
  
    )
}

export default startabusiness
